/* eslint-disable no-nested-ternary */
import React, { FC, useState } from 'react';
import { IEvent } from 'models';
import { Table } from 'components/layout';
import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Menu, Popconfirm, Row, Space, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import config from 'config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useNavigate } from 'react-router-dom';
import EventStore from 'store/EventStore';
import './events-table.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = 'Europe/Moscow';
dayjs.tz.setDefault(tz);

const { Item } = Menu;

interface Props {
    data?: IEvent[] | null;
    pageSize?: number;
    page: number;
    onChangePage: Function;
    onChangeFilter: Function;
    loading: boolean;
    total?: number;
    statusFilter?: string[] | string;
}

const EventsTable: FC<Props> = (props: Props) => {
    const { data, pageSize, page, onChangePage, total, onChangeFilter, statusFilter, loading } =
        props;

    const [loadingSpiner, setLoadingSpiner] = useState(false);

    const navigate = useNavigate();

    const items = [
        {
            key: 'more',
            needConfirm: false,
            label: 'Подробнее',
            action: (id: string) => {
                navigate(`/event/${id}`);
            },
        },
        {
            key: 'activate',
            needConfirm: true,
            titleConfirm: 'Вы уверены что хотите активировать мероприятие?',
            label: 'Активировать',
            action: (id: string) => {
                setLoadingSpiner(true);
                EventStore.activateEvent(id).then(() => {
                    // onChangeFilter('status', statusFilter);
                    setLoadingSpiner(false);
                });
            },
        },
        {
            key: 'cancel',
            needConfirm: true,
            titleConfirm: 'Вы уверены что хотите отменить мероприятие?',
            label: 'Отменить',
            action: (id: string) => {
                setLoadingSpiner(true);
                EventStore.cancelEvent(id).then(() => {
                    onChangeFilter('status', statusFilter);
                    setLoadingSpiner(false);
                });
            },
        },
        {
            key: 'copy',
            label: 'Копировать',
            needConfirm: false,
            action: (id: string) => {
                navigate({
                    pathname: '/event/new',
                    search: `id=${id}`,
                });
            },
        },
        {
            key: 'delete',
            needConfirm: true,
            titleConfirm: 'Вы уверены что хотите удалить мероприятие?',
            label: 'Удалить',
            action: (id: string) => {
                setLoadingSpiner(true);
                EventStore.deleteEvent(id).then(() => {
                    onChangeFilter('status', statusFilter);
                    setLoadingSpiner(false);
                });
            },
        },
    ];

    const columns: ColumnsType<IEvent> = [
        {
            title: 'Название',
            className: 'event-table-name',
            dataIndex: 'name',
            width: '20%',
            render: (_: string, date: IEvent) => {
                return (
                    <>
                        {date?.important === true ? (
                            <span
                                style={{
                                    color: date.important === true ? 'red' : '',
                                    fontWeight: 'bold',
                                }}
                            >
                                !
                            </span>
                        ) : null}
                        {` ${date.name}`}
                    </>
                );
            },
        },
        {
            title: 'Начало',
            dataIndex: 'datetimeStart',
            render: (date: string) => {
                return dayjs(date.replace('Z', '')).tz(tz).format(`${config.dates.def} Мск`);
            },
        },
        {
            title: 'Окончание',
            dataIndex: 'datetimeEnd',
            render: (date: string) => {
                return dayjs(date.replace('Z', '')).tz(tz).format(`${config.dates.def} Мск`);
            },
        },
        {
            title: 'Организатор',
            dataIndex: 'organizer',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            render: (status: { name: string }) => {
                return (
                    <span
                        style={{
                            color:
                                status.name === 'ACTIVE'
                                    ? 'green'
                                    : status.name === 'DELETED'
                                    ? 'red'
                                    : status.name === 'DRAFT'
                                    ? 'orange'
                                    : 'darkgray',
                        }}
                    >
                        {status.name === 'ACTIVE'
                            ? 'Активно'
                            : status.name === 'DELETED'
                            ? 'Удалено'
                            : status.name === 'DRAFT'
                            ? 'Черновик'
                            : 'Отменено'}
                    </span>
                );
            },
            filters: [
                {
                    text: 'Черновик',
                    value: 'DRAFT',
                },
                {
                    text: 'Активные',
                    value: 'ACTIVE',
                },
                {
                    text: 'Отменённые',
                    value: 'CANCELLED',
                },
                {
                    text: 'Удалённые',
                    value: 'DELETED',
                },
            ],
            filtered: statusFilter.length > 0,
            filterMode: 'menu',
            filterDropdown({ filters, selectedKeys, setSelectedKeys, clearFilters, confirm }) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: 175,
                            gap: 8,
                            padding: 10,
                        }}
                    >
                        {filters.map((filterItem) => {
                            return (
                                <Checkbox
                                    key={filterItem.value as string}
                                    defaultChecked={statusFilter.includes(
                                        filterItem.value as string
                                    )}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedKeys([
                                                ...selectedKeys,
                                                filterItem.value as string,
                                            ]);
                                        } else {
                                            setSelectedKeys([
                                                ...selectedKeys.filter(
                                                    (item) => item !== (filterItem.value as string)
                                                ),
                                            ]);
                                        }
                                    }}
                                    style={{ margin: 0 }}
                                >
                                    {filterItem.text}
                                </Checkbox>
                            );
                        })}
                        <Row>
                            <Button
                                onClick={() => {
                                    onChangeFilter('status', []);
                                    clearFilters();
                                    confirm();
                                }}
                                type="link"
                            >
                                Сбросить
                            </Button>
                            <Button
                                onClick={() => {
                                    onChangeFilter('status', selectedKeys);
                                    confirm();
                                }}
                            >
                                Ok
                            </Button>
                        </Row>
                    </div>
                );
            },
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            render: (_: string, row: IEvent) => (
                <Dropdown
                    className="dropdown"
                    trigger={['click']}
                    // @ts-ignore
                    overlay={
                        <Menu>
                            {items.map((item) => {
                                return item.needConfirm ? (
                                    row.status.name === 'DRAFT' && item.key === 'activate' ? (
                                        <Item key={item.key}>
                                            <Popconfirm
                                                title={item.titleConfirm}
                                                onConfirm={() => item.action(row.id || '')}
                                                placement="topRight"
                                                okText="Да"
                                                cancelText="Нет"
                                            >
                                                {item.label}
                                            </Popconfirm>
                                        </Item>
                                    ) : row.status.name !== 'DRAFT' &&
                                      item.key === 'activate' ? null : (
                                        <Item key={item.key}>
                                            <Popconfirm
                                                title={item.titleConfirm}
                                                onConfirm={() => item.action(row.id || '')}
                                                placement="topRight"
                                                okText="Да"
                                                cancelText="Нет"
                                            >
                                                {item.label}
                                            </Popconfirm>
                                        </Item>
                                    )
                                ) : (
                                    <Item onClick={() => item.action(row.id || '')} key={item.key}>
                                        {item.label}
                                    </Item>
                                );
                            })}
                        </Menu>
                    }
                >
                    <Space>
                        Действия...
                        <DownOutlined />
                    </Space>
                </Dropdown>
            ),
            width: '10%',
        },
    ];
    return (
        <Spin spinning={loadingSpiner} delay={500}>
            <Table
                className="events-table"
                data={data}
                page={page}
                pageSize={pageSize}
                total={total}
                columns={columns}
                onChangePage={onChangePage}
                loading={loading}
            />
        </Spin>
    );
};

export default EventsTable;
