/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';

import { Button, Table, Tooltip } from 'components/layout';
import { IRequest } from 'models';
import { IRequestReview } from 'models/requests/IRequest';

import './requests-tsble.scss';

interface Props {
    data?: IRequest[] | null;
    onChangePage: Function;
    page: number;
    total?: number;
    loading: boolean;
    setClickedRow: (row: IRequest) => void;
    setOpenModalComment: (bol: boolean) => void;
}

const RequestsTable: FC<Props> = (props: Props) => {
    const { data, loading, onChangePage, total, page, setClickedRow, setOpenModalComment } = props;

    const columns: ColumnsType<IRequest> = [
        {
            title: 'Имя',
            className: 'event-table-name',
            dataIndex: 'name',
            width: '8%',
        },
        {
            title: 'Компания',
            className: 'event-table-name',
            dataIndex: 'companyName',
            width: '8%',
        },
        {
            title: 'Кол-во резиденств',
            className: 'event-table-name',
            dataIndex: 'residenceCount',
            width: '8%',
        },
        {
            title: 'Номер телефона',
            className: 'event-table-name',
            dataIndex: 'phoneNumber',
            width: '8%',
        },
        {
            title: 'Email',
            className: 'event-table-name',
            dataIndex: 'email',
            width: '8%',
        },
        {
            title: 'Комментарий',
            className: 'event-table-name',
            dataIndex: 'comment',
            width: '8%',
            render: (comment: string) => {
                const isLong = comment?.length > 10;
                const commentPreview = isLong ? `${String(comment)?.slice(0, 10)}…` : comment;

                return (
                    <div className="cell cell-interactive">
                        {isLong ? (
                            <Tooltip title={comment}>{commentPreview}</Tooltip>
                        ) : (
                            commentPreview
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Статус',
            className: 'event-table-name',
            dataIndex: 'status',
            render: (status: string) => {
                return (
                    <span
                        style={{
                            color: status !== 'DONE' ? 'orange' : 'green',
                        }}
                    >
                        {status !== 'DONE' ? 'Ожидает' : 'Обработано'}
                    </span>
                );
            },
            width: '8%',
        },
        {
            title: 'Обработал',
            className: 'event-table-name',
            dataIndex: 'review',
            render: (review: IRequestReview) => <div>{review?.userName || ''}</div>,
            width: '8%',
        },
        {
            title: 'Дата',
            className: 'event-table-name',
            dataIndex: 'review',
            render: (review: IRequestReview) => (
                <div>{dayjs(review?.date).format('DD.MM.YYYY') || ''}</div>
            ),
            width: '8%',
        },
        {
            title: 'Отзыв',
            className: 'event-table-name',
            dataIndex: 'review',
            render: (review: IRequestReview) => <div>{review?.comment || ''}</div>,
            width: '8%',
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            render: (_: string, row: IRequest) =>
                row.status === 'AWAITING' && (
                    <Button
                        key="review"
                        className="reqAction"
                        onClick={() => {
                            setOpenModalComment(true);
                            setClickedRow(row);
                        }}
                    >
                        Обработать
                    </Button>
                ),
            width: '8%',
        },
    ];
    return (
        <Table
            className="orders-table"
            data={data}
            columns={columns}
            loading={loading}
            page={page}
            onChangePage={onChangePage}
            total={total}
        />
    );
};

export default RequestsTable;
