/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { Table } from 'components/layout';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import { IOrder } from 'models/IOrders';
import './orders-table.scss';

const { Item } = Menu;

interface Props {
    data?: IOrder[] | null;
    pageSize?: number;
    page: number;
    onChangePage: Function;
    onCancelEvent: (id: string) => void;
    loading: boolean;
    total?: number;
}

const OrdersTable: FC<Props> = (props: Props) => {
    const { data, pageSize, page, onChangePage, onCancelEvent, total, loading } = props;

    const navigate = useNavigate();

    const items = [
        {
            key: 'more',
            needConfirm: false,
            label: 'Подробнее',
            action: (id: string) => {
                navigate(`/order/${id}`);
            },
        },
        {
            key: 'cancel',
            needConfirm: true,
            titleConfirm: 'Вы уверены что хотите отменить заказ?',
            label: 'Отменить',
            action: (id: string) => onCancelEvent(id),
        },
    ];

    const columns: ColumnsType<IOrder> = [
        {
            title: 'Номер',
            className: 'event-table-name',
            dataIndex: 'orderNumber',
            width: '15%',
        },
        {
            title: 'Статус',
            dataIndex: ['orderStatus', 'description'],
            width: '18%',
            render: (status: string) => {
                return (
                    <span
                        style={{
                            color:
                                status === 'Оплачено' || status === 'Забронировано'
                                    ? 'green'
                                    : status === 'Ошибка оплаты'
                                    ? 'red'
                                    : status === 'Ожидает оплаты'
                                    ? 'burlywood'
                                    : 'darkgray',
                        }}
                    >
                        {status}
                    </span>
                );
            },
            // filters: [
            //     {
            //         text: 'Активные',
            //         value: 'ACTIVE',
            //     },
            //     {
            //         text: 'Отменённые',
            //         value: 'CANCELLED',
            //     },
            //     {
            //         text: 'Удалённые',
            //         value: 'DELETED',
            //     },
            // ],
            // filtered: statusFilter?.length > 0,
            // filterMode: 'menu',
            // filterDropdown({ filters, selectedKeys, setSelectedKeys, clearFilters, confirm }) {
            //     return (
            //         <div
            //             style={{
            //                 display: 'flex',
            //                 flexDirection: 'column',
            //                 justifyContent: 'center',
            //                 width: 175,
            //                 gap: 8,
            //                 padding: 10,
            //             }}
            //         >
            //             {filters.map((filterItem) => {
            //                 return (
            //                     <Checkbox
            //                         key={filterItem.value as string}
            //                         defaultChecked={statusFilter?.includes(
            //                             filterItem.value as string
            //                         )}
            //                         onChange={(e) => {
            //                             if (e.target.checked) {
            //                                 setSelectedKeys([
            //                                     ...selectedKeys,
            //                                     filterItem.value as string,
            //                                 ]);
            //                             } else {
            //                                 setSelectedKeys([
            //                                     ...selectedKeys.filter(
            //                                         (item) => item !== (filterItem.value as string)
            //                                     ),
            //                                 ]);
            //                             }
            //                         }}
            //                         style={{ margin: 0 }}
            //                     >
            //                         {filterItem.text}
            //                     </Checkbox>
            //                 );
            //             })}
            //             <Row>
            //                 <Button
            //                     onClick={() => {
            //                         onChangeFilter('status', []);
            //                         clearFilters();
            //                         confirm();
            //                     }}
            //                     type="link"
            //                 >
            //                     Сбросить
            //                 </Button>
            //                 <Button
            //                     onClick={() => {
            //                         onChangeFilter('status', selectedKeys);
            //                         confirm();
            //                     }}
            //                 >
            //                     Ok
            //                 </Button>
            //             </Row>
            //         </div>
            //     );
            // },
        },
        {
            title: 'Мероприятие',
            className: 'event-table-name',
            dataIndex: ['eventInfo', 'name'],
            // width: '20%',
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            render: (_: string, row: IOrder) => (
                <Dropdown
                    className="dropdown"
                    trigger={['click']}
                    // @ts-ignore
                    overlay={
                        <Menu>
                            {items.map((item) => {
                                return item.needConfirm ? (
                                    <Item key={item.key}>
                                        <Popconfirm
                                            title={item.titleConfirm}
                                            onConfirm={() => item.action(row.orderId || '')}
                                            placement="topRight"
                                            okText="Да"
                                            cancelText="Нет"
                                        >
                                            {item.label}
                                        </Popconfirm>
                                    </Item>
                                ) : (
                                    <Item
                                        onClick={() => item.action(row.orderId || '')}
                                        key={item.key}
                                    >
                                        {item.label}
                                    </Item>
                                );
                            })}
                        </Menu>
                    }
                >
                    <Space>
                        Действия...
                        <DownOutlined />
                    </Space>
                </Dropdown>
            ),
            width: '10%',
        },
    ];
    return (
        <Table
            className="orders-table"
            data={data}
            page={page}
            pageSize={pageSize}
            total={total}
            columns={columns}
            onChangePage={onChangePage}
            loading={loading}
        />
    );
};

export default OrdersTable;
